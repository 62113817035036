import $, { extend } from 'jquery';
import React from 'react';
import {UserToken,getUserObjData} from '../function_lib/userToken';
import {CallMapMethod,AjaxCallMethod,CallMapMethod_CBack,RespBkMthdMapp} from '../function_lib/common_lib';
import Header from '../user_pages/header';
import Footer from '../user_pages/footer';
import Option_Module from '../user_pages/option_module';
import LogoImg from '../assets/images/logo_p_small.png';
import defaultPic from '../assets/images/9.png';

import Comp_Invoice_Products from '../components/comp_invoice_products';
import Comp_Ptr_Invoice from '../components/comp_ptr_invoice';


let loginUser="";
let userObject="";
let companyName="";
let clsOnlineOrders;
class Online_Orders extends React.Component{
  forceUpdateHandler(){
    this.forceUpdate();
  };
    constructor(props){
        super(props);
        if(localStorage.getItem("userData")!=null){
          userObject= getUserObjData();
          //alert(JSON.stringify(userObject));
          loginUser=userObject[0].first_name;
          companyName=userObject[0].first_name;
      }
      this.state={stListInvoice:[],stInvoiceProducts:[],
        stViewInvoice:[],stRefInvoice:"0",stCourierList:[],
      stInvCourier:[],stBillingAdd:[],stShippingAdd:[]};
          this.forceUpdateHandler = this.forceUpdateHandler.bind(this);
          clsOnlineOrders=this;
      }

      setPrtReceipt(item){
        //alert(JSON.stringify(item));
        let encInvoiceId=btoa(item.id);
        clsOnlineOrders.setState({stRefInvoice:encInvoiceId});
      }
/* for child component funciton */

updateOrderStatus(appUserId,invoiceId){
  
  let map=[];
  map.push({name:"curl_type",value:"updateOrderStatus"});
  map.push({name:"res_func",value:"callBackrrr"});
  map.push({name:"local_token",value:appUserId});
  map.push({name:"p1",value:invoiceId});
  map.push({name:"order_status",value:$("#cmdOrderStatus").val()});
  map.push({name:"mail_noti",value:$("#mail_noti").prop('checked')});
  //map.push({name:"acc_inf_id",value:userObject[0].app_user_id});
  
  map["res_func"]="callBackrrr";
  map["curl_type"]="updateOrderStatus";
  CallMapMethod_CBack(this,map,function(data,msg){
    //alert(data+msg);
    if(data!=="0" && data!==""){
         alert(msg);
         window.location.reload();
        }
  });
}
createAutoShipment(appUserId,invoiceId){
  let map=[];
  map.push({name:"curl_type",value:"pushShipmentPack"});
  map.push({name:"res_func",value:"callBackrrr"});
  map.push({name:"local_token",value:appUserId});
  map.push({name:"p1",value:invoiceId});
  map["res_func"]="callBackrrr";
  map["curl_type"]="pushShipmentPack";
  CallMapMethod_CBack(this,map,function(data,msg){
   // alert(data);
    if(data!=="0" && data!==""){
         alert(msg);
         clsOnlineOrders.getInvoiceOrders();
         //window.location.refresh();
         //clsOnlineOrders.getIvoiceProducts(clsOnlineOrders.state.stViewInvoice);
        }
  });

}

updateCourier(appUserId,invoiceId){
  
  let map=[];
  map.push({name:"curl_type",value:"updateOrderDispatch"});
  map.push({name:"res_func",value:"callBackrrr"});
  map.push({name:"local_token",value:appUserId});
  map.push({name:"p1",value:invoiceId});
  map.push({name:"courierId",value:$("#cmbCourier").val()});
  map.push({name:"txtUpdOrderNo",value:$("#txtUpdOrderNo").val()});
  //map.push({name:"acc_inf_id",value:userObject[0].app_user_id});
  
  map["res_func"]="callBackrrr";
  map["curl_type"]="updateOrderDispatch";
  CallMapMethod_CBack(this,map,function(data,msg){
    //alert(data);
    if(data!=="0" && data!==""){
         //alert(msg);
         //window.location.refresh();
         clsOnlineOrders.getInvoiceOrders();
         //clsOnlineOrders.getIvoiceProducts(clsOnlineOrders.state.stViewInvoice);
        }
  });
}
removeOrder(param1,param2){
  //alert(param1);
  let map=[];
  map.push({name:"curl_type",value:"removeExDispatch"});
  map.push({name:"res_func",value:"callBackrrr"});
  map.push({name:"local_token",value:userObject[0].app_user_id});
  map.push({name:"rm_order",value:param1});//order number
  map.push({name:"p1",value:param2});//invoice_id
  //map.push({name:"acc_inf_id",value:userObject[0].app_user_id});
  
  map["res_func"]="callBackrrr";
  map["curl_type"]="removeExDispatch";
  CallMapMethod_CBack(clsOnlineOrders,map,function(data,msg){
    //alert(data+msg);
    if(data==="001"){
      alert(msg+" "+"! Kindly refresh the window to view the changes");
      clsOnlineOrders.getIvoiceProducts(clsOnlineOrders.state.stViewInvoice);

    }
  })
}
deletePeningInvoice(item){
  let map=[];
  map.push({name:"curl_type",value:"deletePendingInvoice"});
  map.push({name:"res_func",value:"callBackrrr"});
  map.push({name:"local_token",value:userObject[0].app_user_id});
  map.push({name:"invoice_id",value:item.id});
  //map.push({name:"acc_inf_id",value:userObject[0].app_user_id});
  
  map["res_func"]="callBackrrr";
  map["curl_type"]="deletePendingInvoice";
  CallMapMethod_CBack(clsOnlineOrders,map,function(data,msg){
    if(data==="001"){
      alert(msg);
      window.location.reload();
    }
  })
  
}
/* end of child component function */
getIvoiceProducts(item){
 // alert(JSON.stringify(item));
  //return;
  var map=[];
  map.push({name:"curl_type",value:"getInvoiceProducts"});
  map.push({name:"res_func",value:"callBackrrr"});
  map.push({name:"local_token",value:userObject[0].app_user_id});
  map.push({name:"invoice_id",value:item.id});
  //map.push({name:"acc_inf_id",value:userObject[0].app_user_id});
  
  map["res_func"]="callBackrrr";
  map["curl_type"]="getInvoiceProducts";
  CallMapMethod_CBack(clsOnlineOrders,map,function(data,msg){
    
    if(data!=="0" && data!==""){
      //alert("i m revised vendor ch");

          let listData=JSON.parse(data);
          //alert(JSON.stringify(listData.inv_breakups));
          var strData="";
          //listData.invoice
          listData["testinvoice"]=[item];
          clsOnlineOrders.setState({stInvoiceProducts:listData.products,stViewInvoice:listData.testinvoice
          ,stInvCourier:listData.inv_breakups,
        stBillingAdd:listData.billing_address,stShippingAdd:listData.shipping_address},()=>{
            
            clsOnlineOrders.forceUpdateHandler();
            RespBkMthdMapp(clsOnlineOrders,"_setbackparam","onDelProCatRel");
          })
        }
  });
}      
resetFilters(){
  $("#ser_OrderProcess").val("0");
  $("#ser_PaymentStatus").val("0");
  $("#ser_from_date").val("");
  $("#ser_to_date").val("");
  $("#ser_txtSearch").val("");
  clsOnlineOrders.getInvoiceOrders();
}
getInvoiceOrders(isSearch=false)
{
        var map=[];
        map.push({name:"curl_type",value:"getInvoiceData"});
        map.push({name:"res_func",value:"callBackrrr"});
        map.push({name:"local_token",value:userObject[0].app_user_id});
        //map.push({name:"acc_inf_id",value:userObject[0].app_user_id});
        map.push({name:"courier_list",value:"yes"});
        map["res_func"]="callBackrrr";
        map["curl_type"]="getInvoiceData";
        if(isSearch===true){
          //alert("search on");
          map.push({name:"ser_order_status",value:$("#ser_OrderProcess").val()});
          map.push({name:"ser_payment_status",value:$("#ser_PaymentStatus").val()});
          map.push({name:"ser_from_date",value:$("#ser_from_date").val()});
          map.push({name:"ser_to_date",value:$("#ser_to_date").val()})
          map.push({name:"ser_txtSearch",value:$("#ser_txtSearch").val()});
        }
        CallMapMethod_CBack(clsOnlineOrders,map,function(data,msg){
          //alert(msg);
            if(data!=="0" && data!==""){
                let listData=JSON.parse(data);

                var strData="";
                clsOnlineOrders.setState({stListInvoice:listData.invoice,
                  stCourierList:listData.courier},()=>{
                  clsOnlineOrders.forceUpdateHandler();
                })
              }
        });
}
componentDidMount(){
   this.getInvoiceOrders();

  AjaxCallMethod(this,"okSuccCNOT");
$("#app_user_id").val(userObject[0].app_user_id);
$('#divInvoiceProducts').on('hidden.bs.modal', function () {
  window.location.reload();
  // do something…
});
}


      render(){
          return(<div>
<Header/>
<div className="content">
<div className="container-fluid mt-4">
<div className="row content12 mt-2 mb-2">
<div className="col-lg-2 col-md-2 col-sm-4 float-right">
<select  className="form-control form-control-sm" id="ser_OrderProcess" name="ser_OrderProcess">
<option value={"0"}>-Order Status-</option>
<option value={"Pending"}>Pending</option>
        <option value={"Under Process"}>Under Process</option>
        <option value={"Package"}>Package</option>
        <option value={"Dispatched"}>Dispatched</option>
        <option value={"Delivered"}>Delivered</option>
</select>
</div>
<div className="col-lg-2 col-md-2 col-sm-4 float-right">
<select  className="form-control form-control-sm" id="ser_PaymentStatus" name="ser_PaymentStatus">
           <option value={"0"}>-Payment Status-</option>
           <option value={"Pending"}>Pending</option>
        <option value={"Unpaid"}>Unpaid</option>
        <option value={"Paid"}>Paid</option>
         </select>
</div>

<div className='col-lg-4 col-md-4 col-sm-6 float-right'>
<div className="form-group">
         <div className="input-group">
               <input autoComplete='off' type="text" id="ser_from_date" name="ser_from_date" data-inputmask-alias="datetime" data-inputmask-inputformat="MM/dd/yyyy" data-mask="true" className="form-control form-control-sm" placeholder='MM/dd/yyyy' />
               -TO-
               <input autoComplete='off' type="text" id="ser_to_date" name="ser_to_date" data-inputmask-alias="datetime" data-inputmask-inputformat="MM/dd/yyyy" data-mask="true" className="form-control form-control-sm" placeholder='MM/dd/yyyy' />
             </div>
         </div>
</div>

<div className='col-lg-2 col-md-2 col-sm-4 float-right'>
<div className="form-group">
         <div className="input-group">
               <input autoComplete='off' type="text" id="ser_txtSearch" name="ser_txtSearch" rf="#btnProSearch" className="_setSearchKeyUp keyEvent_btn form-control form-control-sm" placeholder='search Order No' />
             </div>
         </div>

</div>

<div className="col-lg-2 col-md-2 col-sm-4 vcenter-item1 float-right">
<div className="input-group">
               
               <div className="input-group-prepend">
               <div className='col-12 '>
                 <div id="btnProSearch" name="btnProSearch" onClick={()=>this.getInvoiceOrders(true)}  style={{marginRight:"10px"}} className="btn btn-sm btn-danger">Search</div>
                 <div onClick={()=>this.resetFilters()} className="btn btn-sm btn-danger">Reset</div>
                 </div>
               </div>
             </div>

</div>
    </div>
<div className="row">
      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
      <div class="card">
              {/* <!-- /.card-header --> */}
           <div class="card-header bg-cyan">
                <h3 class="card-title "><i class="fa fa-arrow-circle-right"></i> <b>Online Orders</b></h3>
              </div>
                <table class="dtgrid dtgrid table table-bordered table-hover text-sm text-nowrap1 table-responsive">
                  <thead>
                    <tr class="border">
                      <th>S.No.</th>
                      <th>Customer Name</th>
                      <th>Contact Details</th>
                      <th>Order No.</th> 
                      {/* <th>Tracking No.</th> */}
                      <th>Amount</th>
                      <th>Net Amount</th>
                      <th>Order Status</th>
                      <th>Device</th>
                      <th>Payment Status</th>
                      <th>Transaction Date</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody id="tbData">
                    {this.state.stListInvoice.map((item,index)=>{
                      return(<tr>
                        <td>{index+1}</td>
                    <td>{item.first_name}</td>
                  <td>{item.email_id}{"\r\n"}{"["+item.contact_no+"]"}</td>
                  <td>{item.invoice_no}</td>
                  {/* <td>{item.order_no}</td> */}
                  <td>{item.amount}</td>
                  <td>{item.final_amount}</td>
                  <td>{item.order_status}</td>
                  <td>{item.device}</td>
                  <td>{item.payment_status}</td>
                  <td>{item.month_date}</td>
                  <td> <div class="input-group-prepend">
                    <button type="button" class="btn btn-danger dropdown-toggle" data-toggle="dropdown">
                      Action
                    </button>
                    <div class="dropdown-menu">
                      {item.payment_status==="Paid"?
                      <a class="dropdown-item" onClick={()=>this.getIvoiceProducts(item)} data-toggle="modal" data-target="#divInvoiceProducts" href="javascript:void(0)">View/Process Order</a>
                      :''}
                       {item.payment_status!=="Paid"?
                      <a class="dropdown-item" onClick={()=>this.deletePeningInvoice(item)} href="javascript:void(0)">Delete</a>
                      :''}
                      {/* <a class="dropdown-item" href="#">Order Status</a> */}
                      <a class="dropdown-item" onClick={()=>this.setPrtReceipt(item)} data-toggle="modal" data-target="#divPtrInvoice" href="javascript:void(0)">Print Invoice</a>
                     
                    </div>
                  </div></td>
                    </tr>)
                    })}
                  </tbody>
                </table>
             
              {/* <!-- /.card-body --> */}
            </div>
      </div>
</div>
</div>
</div>
<Footer/>
<div className="modal fade bd-example-modal-xl"  id="divEmployee" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-xl" role="document">
    <div className="modal-content">
    <div className="modal-header bg-primary">
        <h5 className="modal-title text-white" id="exampleModalLabel">Add New</h5>
        <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" className="text-white">&times;</span>
        </button>
      </div>
      <form id="frmCreate" name="frmCreate">
     
      <div className="modal-body">
      <div className="row">
                {/* <!-- left column --> */}
                <div className="col-md-12">
                    <div className="card card-danger">
                            <input type="hidden" name="res_func" id="res_func" value="okSucc" />
                            <input type="hidden" name="curl_type" id="curl_type" value="createLogMaster" />
                            <input type="hidden" name="m_type" id="m_type" value="createLogMaster" />
                            <input type="hidden" name="type_id" id="type_id" value="2" />
                            <input type="hidden" name="app_user_id" id="app_user_id" value="" />
                            <div className="card-body">
                                <div className="row">
                               
                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">License/Registration Name</label>
                                            <div className="input-group input-group-sm">
                                                <input type="text" id="details" name="details" className="form-control" placeholder="" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Type *</label>
                                            <div className="input-group input-group-sm">
                                                <select id="parent_id_reg" name="parent_id_reg" className="form-control">
                                                  <option value="0">-Select-</option>
                                                  <option value="entity">Entity</option>
                                                  <option value="contractor">Contractor</option>
                                                  <option value="client">Client</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-12">
            <div className="form-group">
              <label for="exampleInputEmail1">Valid From</label>
              <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text"><i className="fa fa-calendar"></i></span>
                    </div>
                    <input type="text" id="col98" name="col98" className="form-control" data-inputmask-alias="datetime" data-inputmask-inputformat="dd/mm/yyyy" data-mask />
                  </div>
              <small id="emailHelp" className="form-text text-muted">Proposal Form Sign Date</small> </div>
          </div>
                                    <div className="col-lg-3 col-md-3 col-12">
            <div className="form-group">
              <label for="exampleInputEmail1">Valid To</label>
              <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text"><i className="fa fa-calendar"></i></span>
                    </div>
                    <input type="text" id="col98" name="col98" className="form-control" data-inputmask-alias="datetime" data-inputmask-inputformat="dd/mm/yyyy" data-mask />
                  </div>
              <small id="emailHelp" className="form-text text-muted">Proposal Form Sign Date</small> </div>
          </div>
                                    <div className="col-lg-12 col-md-12">
                                    <div className="form-group">
<label for="exampleInputFile">Upload Proof(*img,pdf)</label>
<div className="input-group">
  <div className="custom-file">
    <input type="file" className="custom-file-input" name="exampleInputFile" id="exampleInputFile" />
    <label className="custom-file-label" for="exampleInputFile">Choose file</label>
  </div>
  {/* <div className="input-group-append">
  <button type="button" className="btn btn-primary">Upload Now</button>
  </div> */}
</div>
</div>
                                    </div>
                                    <div className="col-lg-3 col-md-6">
                                    <div className="form-group">
                                            <label for="exampleInputEmail1">Reminder Breif(Days) *</label>
                                            <div className="input-group input-group-sm">
                                                <input type="text" id="ref1_id" name="ref1_id" className="form-control" placeholder="Priority Index" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                              
                                <div className="col-md-6 respSSL"></div>
                                <div id="testing2dd2222"></div>
                            </div>
                        
                    </div>
                </div>
            </div>
      </div>
      <div className="modal-footer">
      <div id="getReponse" style={{color:"red"}}></div>
        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" className="btn _btnSetColor btn-primary">Save changes</button>
      </div>
      </form>
    </div>
    </div>
    </div>
    <Comp_Invoice_Products inv_courier={this.state.stInvCourier} updateOrderStatus={this.updateOrderStatus} pushAutoShipment={this.createAutoShipment} 
    updateCourier={this.updateCourier} 
    mainInvoice={this.state.stViewInvoice} 
    invoiceProducts={this.state.stInvoiceProducts} 
    courierList={this.state.stCourierList} 
    app_user_id={userObject[0].app_user_id}
    billingAddress={this.state.stBillingAdd}
    shippingAddress={this.state.stShippingAdd}
    />
    <Comp_Ptr_Invoice app_user_id={userObject[0].app_user_id} ref_invoiceId={this.state.stRefInvoice} />
          </div>)
      }
}
export default Online_Orders;